<template>
  <div class="product">
    <div class="znbw">
      <div class="znbw_box">
        <p>智能办文</p>
        <p>同一系统，线上统一实现日常办公，全流程高效简便</p>
        <p>来文呈批 | 发文办理 | 电子签批 | 文件归档</p>
        <div class="btn">在线咨询</div>
      </div>
    </div>
    <div class="hxld">
      <p>核心亮点</p>
      <div class="hxld_box">
        <div class="hxld_box_item" id="hxld_box_item1">
          <img src="@/assets/images/product/znbw/hxld_icon1.png" />
          <p>灵活定制</p>
          <p>根据不同部门和业务类型<br />个性化定制线上签批流程</p>
        </div>
        <div class="hxld_box_item" id="hxld_box_item2">
          <img src="@/assets/images/product/znbw/hxld_icon2.png" />
          <p>紧急提醒</p>
          <p>按特提、特急、急、平分类<br />根据紧急程度，自动短信提醒</p>
        </div>
        <div class="hxld_box_item" id="hxld_box_item3">
          <img src="@/assets/images/product/znbw/hxld_icon3.png" />
          <p>编辑留痕</p>
          <p>办文过程中可在线编辑文档<br />文档编辑后对修改动作留痕</p>
        </div>
        <div class="hxld_box_item" id="hxld_box_item4">
          <img src="@/assets/images/product/znbw/hxld_icon4.png" />
          <p>领导签批</p>
          <p>领导真迹签名绑定系统账号<br />签批文件后显示真迹签名</p>
        </div>
      </div>
    </div>
    <div class="gzlc">
      <div class="gzlc_content">
        <div class="gzlc_content_left">
          <img src="@/assets/images/product/znbw/gzlc.png" />
          <p class="title">
            深耕智慧政务多年，熟悉政务运作流程 可按需求灵活设计办文工作流程
          </p>
          <p class="text">
            各单位使用同一系统，线上统一实现来文呈批、发文办理、电子签批、文件归档等日程办公功能
            全流程高效简便，网上办文代替人手办文，节约行政成本，提高行政效率。
          </p>
        </div>
        <div class="gzlc_box">
          <div class="gzlc_box_item" id="gzlc_box_item1">
            <img src="@/assets/images/product/znbw/gzlc_icon1.png" />
            <div class="gzlc_box_item_content">
              <p>协同签批</p>
              <p>领导顺签、会签，多科室部门协同审核签批</p>
            </div>
          </div>
          <div class="gzlc_box_item" id="gzlc_box_item2">
            <img src="@/assets/images/product/znbw/gzlc_icon2.png" />
            <div class="gzlc_box_item_content">
              <p>移动签批</p>
              <p>领导顺签、会签，多科室部门协同审核签批</p>
            </div>
          </div>
          <div class="gzlc_box_item" id="gzlc_box_item3">
            <img src="@/assets/images/product/znbw/gzlc_icon3.png" />
            <div class="gzlc_box_item_content">
              <p>协同签批</p>
              <p>领导顺签、会签，多科室部门协同审核签批</p>
            </div>
          </div>
          <div class="gzlc_box_item" id="gzlc_box_item4">
            <img src="@/assets/images/product/znbw/gzlc_icon4.png" />
            <div class="gzlc_box_item_content">
              <p>协同签批</p>
              <p>领导顺签、会签，多科室部门协同审核签批</p>
            </div>
          </div>
        </div>
        <div class="last_line">
          <div class="gzlc_box_item" id="gzlc_box_item5">
            <img src="@/assets/images/product/znbw/gzlc_icon5.png" />
            <div class="gzlc_box_item_content">
              <p>文件督办</p>
              <p>可实时监督文件签收进程，确保文件签收及时性</p>
            </div>
          </div>
          <div class="gzlc_box_item" id="gzlc_box_item6">
            <img src="@/assets/images/product/znbw/gzlc_icon6.png" />
            <div class="gzlc_box_item_content">
              <p>公文交换</p>
              <p>系统自带上下级、同级、部门之间的公文交换功能</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data () {
    return {

    }
  },
  mounted () {
    setTimeout(() => {
      $('#hxld_box_item1').addClass('animation')
      $('#hxld_box_item2').addClass('animation')
      $('#hxld_box_item3').addClass('animation')
      $('#hxld_box_item4').addClass('animation')
    }, 100)
    $(window).scroll(this.scrollBar)
  },
  methods: {
    scrollBar () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      var gzlcH = $('.gzlc').offset().top
      if (h > gzlcH - 500) {
        $('#gzlc_box_item1').addClass('animation')
        $('#gzlc_box_item2').addClass('animation')
        $('#gzlc_box_item3').addClass('animation')
        $('#gzlc_box_item4').addClass('animation')
        $('#gzlc_box_item5').addClass('animation')
        $('#gzlc_box_item6').addClass('animation')
      }
    }
  },
  destroyed () {
    $(window).unbind('scroll', this.scrollBar)
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
